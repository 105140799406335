<template>
  <div class="home">
    <div class="header">
      <div class="left">
        <img class="google" src="@/assets/googleplay.png" alt="" />
      </div>
      <div class="right">
        <img class="search" src="@/assets/searchIcon.png" alt="" />
        <img class="question" src="@/assets/questionIcon.png" alt="" />
      </div>
    </div>
    <div class="main">
      <div class="productTitle">
        <div class="left">
          <img src="@/assets/productImg.png" alt="" />
        </div>
        <div class="right">
          <span class="loanApp">Swtich My Loan-loan cash app</span>
          <span>Swtich FINANCE</span>
        </div>
      </div>
      <div class="about">
        <div class="box">
          <div class="top">
            4.8
            <img
              style="width: 11px; height: 11px"
              src="@/assets/blackStar.png"
              alt=""
            />
          </div>
          <div class="text">2.89M reviews</div>
        </div>
        <div class="hr">|</div>
        <div class="box">
          <div class="top">8M</div>
          <div class="text">Downloads</div>
        </div>
        <div class="hr">|</div>
        <div class="box">
          <div class="top">
            <img
              style="width: 16px; height: 16px; margin-bottom: -4px"
              src="@/assets/everyIcon.png"
              alt=""
            />
          </div>
          <div class="text">
            Everyone
            <img
              style="width: 12px; height: 12px; vertical-align: middle"
              src="@/assets/infoIcon.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <a :href="url" class="AdjustTracker"> Install </a>
      <div class="shareadd">
        <div class="left">
          <img src="@/assets/shareIcon.png" alt="" />
          <span>Share</span>
        </div>
        <div class="right">
          <img src="@/assets/addIcon.png" alt="" />
          <span>Add to wishlist</span>
        </div>
      </div>
      <div class="devices">
        <img src="@/assets/devicesIcon.png" alt="" />
        This app is available for all of your devices
      </div>
      <div class="pic">
        <img preview="0" src="@/assets/one.png" alt="" @click="show" />
        <img preview="0" src="@/assets/two.png" alt="" @click="show" />
        <img preview="0" src="@/assets/three.png" alt="" @click="show" />
        <img preview="0" src="@/assets/four.png" alt="" @click="show" />
        <img preview="0" src="@/assets/five.png" alt="" @click="show" />
        <img preview="0" src="@/assets/six.png" alt="" @click="show" />
      </div>
      <h3 class="aboutApp">
        About this app
        <img class="rightIcon" src="@/assets/rightIcon.png" alt="" />
      </h3>
      <div class="loan">
        Loan products provided by Swtich My Loan-loan cash app:
        <br />
        <p style="margin-top: 12px">-Maximum loan amount: Rs 20,000</p>
        <p>-Loan period: minimum 91 days, maximum 180 days</p>
      </div>
      <div class="loan" style="margin-top: 16px">
        Long-term personal loans and multiple payment options are available.
      </div>
      <div class="loan" style="margin-top: 0">
        Swtich My Loan-loan cash app is India's trusted credit app. We are a
        Made in India app, 100% digital, offering long-term personal loans and
        multiple payment options.
      </div>
      <div class="loan" style="color: #202124; font-weight: 550">
        Updated on
      </div>
      <div class="loan" style="margin-top: 6px">Jan 21,2024</div>
      <div class="finance">
        <div class="btn">#1 top free finance</div>
        <div class="btn">Finance</div>
      </div>
      <h3 class="aboutApp">
        Data safety
        <img class="rightIcon" src="@/assets/rightIcon.png" alt="" />
      </h3>
      <div class="loan">
        Safety starts with understanding how developers collect and share your
        data. Data privacy and security practices may vary based on your use,
        region, and age. The developer provided this information and may update
        it over time.
      </div>
      <div class="details">
        <div class="one">
          <div class="left">
            <img
              style="width: 21px; height: 19px"
              src="@/assets/share.png"
              alt=""
            />
          </div>
          <div class="right">
            No data shared with third parties
            <br />
            <span style="font-size: 12px"
              ><span style="text-decoration: underline">Learn more</span> about
              how developers declare sharing</span
            >
          </div>
        </div>
        <div class="one">
          <div class="left">
            <img
              style="width: 23px; height: 19px"
              src="@/assets/collect.png"
              alt=""
            />
          </div>
          <div class="right">
            This app may collect these data types
            <br />
            <span style="font-size: 12px"
              >Location, Personal info and 7 others</span
            >
          </div>
        </div>
        <div class="one">
          <div class="left">
            <img
              style="width: 19px; height: 19px"
              src="@/assets/encrypted.png"
              alt=""
            />
          </div>
          <div class="right">Data is encrypted in transit</div>
        </div>
        <div class="one">
          <div class="left">
            <img
              style="width: 20px; height: 23px"
              src="@/assets/delete.png"
              alt=""
            />
          </div>
          <div class="right">You can request that data be deleted</div>
        </div>
        <div class="see">See details</div>
      </div>
      <h3 class="aboutApp">
        Ratings and reviews
        <img class="rightIcon" src="@/assets/rightIcon.png" alt="" />
      </h3>
      <div class="verified">
        Ratings and reviews are verified
        <img src="@/assets/info_black.png" alt="" />
      </div>
      <div style="display: flex">
        <div class="phone">
          <img src="@/assets/phone.png" alt="" />
          Phone
        </div>
        <div
          class="phone"
          style="
            border-radius: 17px;
            border: 1px solid #dadce0;
            background-color: #fff;
            margin-left: 16px;
            color: #5f6368;
          "
        >
          <img
            style="width: 21px; height: 25px"
            src="@/assets/tablet.png"
            alt=""
          />
          Tablet
        </div>
      </div>
      <div
        class="phone"
        style="
          border-radius: 17px;
          border: 1px solid #dadce0;
          background-color: #fff;
          margin-top: 14px;
          width: 120px;
          padding: 7px 15px;
          color: #5f6368;
        "
      >
        <img style="width: 24px; height: 19px" src="@/assets/book.png" alt="" />
        Chromebook
      </div>
      <div class="score">
        <div class="left">
          <p style="margin-bottom: -55%">4.8</p>
          <img
            src="@/assets/greenStar.png"
            alt=""
            v-for="item in 4"
            :key="item"
          />
          <img src="@/assets/greenBlankStar.png" alt="" />
          <p class="reviews">2.82M reviews</p>
        </div>
        <div class="right">
          <div class="number">
            5
            <span
              style="
                position: absolute;
                background: #e8eaed;
                border-radius: 6px;
                height: 11px;
                width: 204px;
              "
            ></span>
            <span
              style="
                position: absolute;
                background: #03875f;
                border-radius: 6px;
                height: 11px;
                width: 180px;
              "
            >
            </span>
          </div>
          <div class="number">
            4
            <span
              style="
                position: absolute;
                background: #e8eaed;
                border-radius: 6px;
                height: 11px;
                width: 204px;
              "
            ></span>
            <span
              style="
                position: absolute;
                background: #03875f;
                border-radius: 6px;
                height: 11px;
                width: 16px;
              "
            >
            </span>
          </div>
          <div class="number">
            3
            <span
              style="
                position: absolute;
                background: #e8eaed;
                border-radius: 6px;
                height: 11px;
                width: 204px;
              "
            ></span>
            <span
              style="
                position: absolute;
                background: #03875f;
                border-radius: 6px;
                height: 11px;
                width: 5px;
              "
            >
            </span>
          </div>
          <div class="number">
            2
            <span
              style="
                position: absolute;
                background: #e8eaed;
                border-radius: 6px;
                height: 11px;
                width: 204px;
              "
            ></span>
            <span
              style="
                position: absolute;
                background: #03875f;
                border-radius: 6px;
                height: 11px;
                width: 12px;
              "
            >
            </span>
          </div>
          <div class="number">
            1
            <span
              style="
                position: absolute;
                background: #e8eaed;
                border-radius: 6px;
                height: 11px;
                width: 204px;
              "
            ></span>
            <span
              style="
                position: absolute;
                background: #03875f;
                border-radius: 6px;
                height: 11px;
                width: 1px;
              "
            >
            </span>
          </div>
        </div>
      </div>
      <div class="people">
        <div class="top">
          <div class="left">
            <img src="@/assets/people1.png" alt="" />
            <span>Dheeraj Joshi</span>
          </div>
          <div class="right">
            <img src="@/assets/more.png" alt="" />
          </div>
        </div>
        <div class="evaluate">
          <img
            src="@/assets/greenStar.png"
            alt=""
            v-for="item in 5"
            :key="item"
          />
          <span style="margin-left: 13px">June 8, 2024</span>
          <p class="text" style="margin-left: 0">
            One of the best options to request a loan. You decide when to pay
            (Obviously the longer you put in, the higher the interest) but it is
            wonderful how the interest is the lowest you will find in loan apps.
            They don't saturate you with messages or calls, just a discreet
            notification days before your payment. Recommendable.
          </p>
          <p class="helpful">523 people found this review helpful</p>
          <div class="find">
            <div class="did">Did you find this helpful?</div>
            <span class="sure" style="margin-left: 16px">Yes</span>
            <span class="sure" style="margin-left: 6px">No</span>
          </div>
          <div class="other">
            <span style="color: #202124">Switch FlN…</span>
            <span style="float: right">January 9, 2024</span>
            <p>
              Thank you for your support. We are always committed to improving
              users' lending experience.
            </p>
          </div>
        </div>
      </div>
      <div class="people">
        <div class="top">
          <div class="left">
            <img src="@/assets/people2.png" alt="" />
            <span>Dungar Bhai</span>
          </div>
          <div class="right">
            <img src="@/assets/more.png" alt="" />
          </div>
        </div>
        <div class="evaluate">
          <img
            src="@/assets/greenStar.png"
            alt=""
            v-for="item in 5"
            :key="item"
          />
          <span style="margin-left: 13px">March 23, 2024</span>
          <p class="text" style="margin-left: 0">
            This is a great option to get out of financial trouble, they are
            very clear from the start, the interest is low and they give you a
            very reasonable payment term, I recommend it without a doubt, I am
            very happy with this app.
          </p>
          <p class="helpful">427 people found this review helpful</p>
          <div class="find">
            <div class="did">Did you find this helpful?</div>
            <span class="sure" style="margin-left: 16px">Yes</span>
            <span class="sure" style="margin-left: 6px">No</span>
          </div>
        </div>
      </div>
      <div class="people">
        <div class="top">
          <div class="left">
            <img src="@/assets/people3.png" alt="" />
            <span>Ian Davis (Snakeman)</span>
          </div>
          <div class="right">
            <img src="@/assets/more.png" alt="" />
          </div>
        </div>
        <div class="evaluate">
          <img
            src="@/assets/greenStar.png"
            alt=""
            v-for="item in 5"
            :key="item"
          />
          <span style="margin-left: 13px">February 12, 2024</span>
          <p class="text" style="margin-left: 0">
            At first I didn't have much confidence in them, but little by little
            I have increased my credit, easy payments up to 30 days, the
            interest is not much, they don't harass you by calling you all the
            time, they do send you messages but very little and they deposit you
            the agreed amount.
          </p>
          <p class="helpful">37 people found this review helpful</p>
          <div class="find">
            <div class="did">Did you find this helpful?</div>
            <span class="sure" style="margin-left: 16px">Yes</span>
            <span class="sure" style="margin-left: 6px">No</span>
          </div>
          <div class="see">See all reviews</div>
        </div>
      </div>
      <h3 class="aboutApp">What's new</h3>
      <p class="various">Various bug fixes and improvements.</p>
      <h3 class="aboutApp">
        App support
        <img
          style="width: 24px; height: 24px"
          src="@/assets/expand_more.png"
          alt=""
        />
      </h3>
      <div class="flag">
        <img src="@/assets/flag.png" alt="" /> Flag as inappropriate
      </div>
      <h4>Google Play</h4>
      <p class="text">Play Pass</p>
      <p class="text">Play Points</p>
      <p class="text">Gift cards</p>
      <p class="text">Redeem</p>
      <p class="text">Refund policy</p>
      <h4>Refund policy</h4>
      <p class="text">Parent Guide</p>
      <p class="text">Family sharing</p>
      <div class="footer">
        <p>Terms of Service</p>
        <p>Privacy</p>
        <p>About Google Play</p>
      </div>
      <div class="footer" style="justify-content: left">
        <p style="margin-right: 38px">Developers</p>
        <p>Google Store</p>
      </div>
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
        भारत (हिन्दी)
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      url:
        'https://app.adjust.com/1gw01sub?engagement_type=fallback_click&redirect=' +
        encodeURIComponent(
          `https://${window.location.hostname}/download/com.switch.my.loan.apk`
        )
    }
  },
  created () {},
  methods: {
    show () {
      this.$previewRefresh()
    }
  }
}
</script>
<style scoped lang="less">
.header {
  padding: 15px 23px;
  background-color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  .google {
    width: 159px;
    height: 36px;
    margin-right: 100px;
  }
  .search {
    width: 20px;
    height: 20px;
    margin-right: 25px;
  }
  .question {
    width: 22px;
    height: 24px;
  }
}
.main {
  padding: 23px;
  padding-top: 50px;
}
.productTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  font-family: HarmonyOS_Sans_SC_Medium;
  text-align: left;
  .left {
    img {
      width: 81px;
      height: 81px;
    }
  }
  .right {
    margin-left: 16px;
    .loanApp {
      font-size: 22px;
      color: #202124;
      margin-bottom: 7px;
      line-height: 23px;
    }
    span {
      display: block;
      line-height: 19px;
      font-size: 15px;
      color: #03875f;
      line-height: 19px;
    }
  }
}
.about {
  display: flex;
  font-size: 12px;
  font-family: Roboto, Roboto;
  margin-top: 37px;
  justify-content: space-between;
  margin-bottom: 37px;
  .box {
    .text {
      color: #6a6e73;
      margin-top: 6px;
    }
    .top {
      font-weight: 600;
    }
  }
  .hr {
    color: #d5d5d5;
    font-size: 24px;
  }
}
a {
  background-color: #01875f;
  border-radius: 8px;
  padding: 8px 0;
  font-size: 14px;
  font-family: HarmonyOS_Sans_SC_Medium;
  color: #ffffff;
  text-decoration: none;
  display: block;
}
.shareadd {
  display: flex;
  justify-content: space-evenly;
  margin-top: 17px;
  font-family: HarmonyOS_Sans_SC_Medium;
  color: #03875f;
  font-size: 12px;
  align-items: center;
  .left {
    font-size: 12px;
    img {
      width: 24px;
      height: 24px;
      margin-left: 7px;
      vertical-align: middle;
    }
  }
  .right {
    font-size: 14px;
    img {
      width: 14px;
      height: 19px;
      margin-right: 13px;
      vertical-align: middle;
    }
  }
}
.devices {
  color: #65686c;
  display: flex;
  font-size: 14px;
  margin-top: 30px;
  img {
    width: 18px;
    height: 16px;
    margin-right: 11px;
  }
}
.rightIcon {
  width: 16px;
  height: 14px;
}
.aboutApp {
  font-size: 18px;
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loan {
  font-family: Roboto, Roboto;
  line-height: 19px;
  color: #5f6368;
  font-size: 14px;
  text-align: left;
  margin-top: 21px;
}
.finance {
  margin-top: 29px;
  display: flex;
  font-size: 14px;
  color: #5f6368;
  font-family: HarmonyOS_Sans_SC;
  .btn {
    border: 1px solid #dadce0;
    padding: 6px 15px 7px 18px;
    border-radius: 17px;
    margin-right: 13px;
  }
}
.details {
  border-radius: 8px;
  border: 1px solid #dadce0;
  margin-top: 22px;
  color: #5f6368;
  font-family: Roboto, Roboto;
  font-size: 14px;
  padding: 24px 14px 20px;
  text-align: left;
  .one {
    display: flex;
    margin-bottom: 17px;
    .left {
      margin-right: 10px;
    }
  }
  .see {
    color: #03875f;
    font-size: 14px;
    text-align: left;
    margin-left: 20px;
  }
}
.verified {
  font-size: 12px;
  margin-top: 18px;
  text-align: left;
  margin-bottom: 24px;
  img {
    width: 16px;
    height: 17px;
    vertical-align: middle;
    margin-left: 5px;
  }
}
.phone {
  border-radius: 17px;
  background: #e6f3ef;
  font-size: 14px;
  color: #056449;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 19px;
    height: 23px;
    vertical-align: middle;
  }
}
.score {
  margin-top: 36px;
  display: flex;
  font-family: Roboto, Roboto;
  align-items: center;
  .left {
    font-size: 60px;
    img {
      width: 17px;
      height: 17px;
      margin-top: -50px;
    }
    .reviews {
      color: #5f6368;
      font-size: 12px;
      font-family: HarmonyOS_Sans_SC;
    }
  }
  .right {
    margin-left: 26px;
    font-size: 12px;
    color: #5f6368;
    span {
      margin-left: 16px;
    }
    .number {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}
.people {
  margin-top: 29px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-family: Roboto, Roboto;
      font-size: 14px;
      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .right {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .evaluate {
    text-align: left;
    margin-top: 13px;
    color: #5f6368;
    font-size: 13px;
    line-height: 19px;
    img {
      width: 15px;
      height: 15px;
    }
    .helpful {
      margin-top: 21px;
    }
    .text {
      font-size: 14px;
      margin-top: 13px;
    }
    .find {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .sure {
        padding: 3px 18px;
        font-size: 14px;
        border: 1px solid #dadce0;
        border-radius: 17px;
      }
    }
    .other {
      margin-top: 18px;
      border-radius: 6px;
      background: #f8f9fa;
      padding: 14px 14px 21px 21px;
      margin-left: 30px;
      p {
        margin-top: 16px;
      }
    }
    .see {
      color: #03875f;
      font-size: 14px;
      margin-top: 42px;
      font-family: HarmonyOS_Sans_SC_Medium;
    }
  }
}
.various {
  margin-top: 15px;
  color: #5f6368;
  font-family: Roboto, Roboto;
  font-size: 12px;
  text-align: left;
}
.flag {
  margin-top: 35px;
  color: #202124;
  font-family: HarmonyOS_Sans_SC_Medium;
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 33px;
  img {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}
h4 {
  margin-top: 33px;
  color: #5f6368;
  font-family: Roboto, Roboto;
  font-size: 16px;
  text-align: left;
  margin-left: 6px;
}
.text {
  font-family: Roboto, Roboto;
  font-size: 14px;
  margin-left: 6px;
  margin-top: 15px;
  text-align: left;
  color: #5f6368;
}
.footer {
  color: #5f6368;
  font-family: Roboto, Roboto;
  font-size: 12px;
  display: flex;
  margin-top: 37px;
  justify-content: space-between;
  margin-left: 6px;
}
.logo {
  margin-top: 30px;
  margin-left: 6px;
  text-align: left;
  color: #5f6368;
  font-family: KohinoorDevanagari, KohinoorDevanagari;
  font-size: 12px;
  margin-bottom: 30px;
  img {
    width: 25px;
    height: 19px;
    margin-right: 9px;
  }
}
.pic {
  margin-top: 16px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  img {
    width: 113px;
    height: 199px;
    margin-right: 3px;
  }
}
</style>
